import React from 'react';

import { BASE_ZINDEX } from '../popup-factory';

const ClosedShopPopup = ({ index }) => (
  <div className="reveal tiny" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
    <div className="row small-12 columns">
      <h3 style={{ textAlign: 'center' }}>Sorry, this shop is closed.</h3>
    </div>
  </div>
);
export default ClosedShopPopup;

